import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import useDeviceDetect from '../../utils/useDeviceDetect';
import { Link } from 'gatsby';

const InterestSection = ({
  name,
  link
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  return (
    <div className={`_interestSection-wrapper`}>
      <div className={`_interestSection-inner-wrapper`}>
        <div className={
          `${isMobile ? 'text-mobile-h1' : 'text-web-h1'} _interestSection-mainTitle`
        }>
          {t('interest.title')}
        </div> 
        <div className={`_interestSection-relatedSection`}>
          <div className={
            `${isMobile ? 'text-mobile-h2' : 'text-web-h2'} _interestSection-title`
          }>
            {t(`${name}.header.title`)}
          </div>
          <div className={
            `${isMobile ? 'text-mobile-paragraph-small' : 'text-web-paragraph-medium'} _interestSection-description`
          }>
            {t(`${name}.header.description`)}
          </div>
          <div className={`_interestSection-actionButton`} hidden={isMobile}>
            <Button filled={true} to={link}>{t('interest.actionButton')}</Button>
          </div>
          <Link className={`_interestSection-actionLink`} to={link}>{t('interest.actionButton')}</Link>
        </div>
      </div>
    </div>
  )
};

export default InterestSection;
