import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';

import Navbar from '../components/Navbar';
import PageHeader from '../components/PageHeader';
import SupportLogos from '../components/SupportLogos';
import SectionTitle from '../components/SectionTitle';
import ContactSection from '../components/ContactSection';
import Section from '../components/Section';
import mitLogo from '../images/pictures/mitLogo.svg';
import cideLogo from '../images/pictures/cideLogo.png';
import innovateLogo from '../images/pictures/innovateLogo.svg';
import InterestSection from '../components/InterestSection';
import Footer from '../components/Footer';
import getMetadata from '../utils/getMetadata';
import '../styles/index.scss';
import FloatButton from "../components/FloatButton";
import wppWhiteIcon from '../images/icons/wppWhite.svg'; 

const Home = ({ pageContext, location }) => {
  const { lang } = pageContext;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [])

  return (
    <>
      {
        getMetadata({
          pageName: 'contact',
          link: 'contact',
          lang,
          t,
        })
      }
      <Navbar location={location}/>
      <FloatButton
        image={wppWhiteIcon}
        imageAlt={t('floatButton.wpp.imageAlt')}
        link={`https://wa.me/51998785550?text=${encodeURIComponent(t('floatButton.wpp.text'))}`}
        isExternalLink={true}
      />
      <PageHeader
        title={t(`contact.header.title`)}
        description={t(`contact.header.description`)}
        photo='contact'
        photoAlt={t('contact.header.imageAlt')}
      />
      <ContactSection
        mobileMainTitle={t('contact.contactSection.title')}
        title={t('contact.contactSection.subTitle')}
      />
      <Section
        title={t('home.sections.support.title')}
        isGray={true}
        items={[
          (
            <SectionTitle
              title={t('home.sections.support.subTitle')}
              description={t('home.sections.support.description')}
              bullets={true}
            />
          ),
          (
            <SupportLogos
              logos={[
                {
                  logo: innovateLogo,
                  logoAlt: t('home.sections.support.innovateLogoAlt')
                }, {
                  logo: mitLogo,
                  logoAlt: t('home.sections.support.mitLogoAlt')
                },
                {
                  logo: cideLogo,
                  logoAlt: t('home.sections.support.cideLogoAlt')
                },
              ]}
            />
          )
        ]}
      />
      <InterestSection
        name='services'
        link={`/${lang}/services`}
      />
      <Footer lang={lang}/>
    </>
  );
};

export default Home;
