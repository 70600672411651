import React from 'react';
import useDeviceDetect from '../../utils/useDeviceDetect';

import Spacer from '../Spacer';

const SupportLogos = ({
  logos,
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div className={`_supportLogos`}>
      {
        logos.map(({ logo, logoAlt }, index) => (
          <div key={index}>
            <img className={`_supportLogos-image`} src={logo} alt={logoAlt} />
            {
              isMobile && index + 1 <= logos.length && (
                <Spacer noLine={true} />
              )
            }
          </div>
        ))
      }
    </div>
  )
};

export default SupportLogos;
